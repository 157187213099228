<template>
  <div class="container">
    <div class="filters">
      <div v-if="showGroupStatistic" class="static-info">Сообществ: {{ getGroupCount }}</div>

      <auditory-filter />

      <radio-filter
        :buttons="{ list: period, groupName: 'period' }"
        title="Период"
        @updateItem="updateItem"
      ></radio-filter>

      <radio-filter
        :buttons="{ list: type, groupName: 'type' }"
        title="Тип"
        @updateItem="updateItem"
      ></radio-filter>

      <radio-filter
        v-if="type[2]?.checked"
        :buttons="{ list: status, groupName: 'status' }"
        title="Статус"
        @updateItem="updateItem"
      ></radio-filter>

      <check-box
        :items="checkButtons"
        class="filters__checkbox"
        @emitState="updateCheckBox"
      ></check-box>
    </div>

    <hr class="line" />

    <div class="categories" v-if="showCatalogs">
      <div class="categories__catalogs">
        <div class="categories__panel">
          <p class="categories__title">Категории</p>

          <close-icon @click="reset"></close-icon>
        </div>

        <catalog-folder
          :ref="'folders' + index"
          v-for="(catalog, index) in getCategory"
          :key="index"
          :projects="catalog"
          @emmiter="setClass"
          @reset="resetCatalog"
        >
        </catalog-folder>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AuditoryFilter from '@/components/common/filter/TheAuditoryFilter.vue';
import RadioFilter from '@/components/common/filter/RadioButtonFilter.vue';
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';
import CatalogFolder from '@/components/common/catalogs/CatalogFolder.vue';
import CheckBox from '@/components/atom/button/check/CheckBox.vue';
import routing from '@/utils/routing';

export default {
  components: {
    AuditoryFilter,
    RadioFilter,
    CloseIcon,
    CatalogFolder,
    CheckBox,
  },
  props: {
    showGroupStatistic: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    getCategory(newValue) {
      if (newValue.length !== 0) {
        this.checkLinkByRouteParams();
      }
    },
  },

  computed: {
    ...mapGetters('vkValues', ['getPeriod', 'getType', 'getStatus', 'getCheckButtons']),
    ...mapGetters('vkRequests', ['getCategory', 'getGroupCount', 'getAdminsId']),
  },
  data() {
    return {
      period: [],
      type: [],
      status: [],
      checkButtons: [],

      showCatalogs: true,
      activeLink: null,
      isParamsNeed: true,
    };
  },
  methods: {
    ...mapActions('vkRequests', [
      'requestByDate',
      'requestByType',
      'requestByStatus',
      'requestByVerification',
      'requestByCategory',
      'cancleRequest',
    ]),
    ...mapActions('routerControl', ['setRoute']),
    ...mapMutations('vkValues', ['setShowNumeration', 'setShowFavorites']),

    updateCheckBox(checkbox) {
      if (checkbox.name === 'verification') {
        this.requestByVerification(checkbox.isChecked);
      } else {
        this.checkContentByCheckbox(checkbox);
      }
    },
    // новые методы
    checkContentByCheckbox(checkbox) {
      let closePage = '';
      let favorites;
      let indexes;

      if (checkbox.name === 'favorites') {
        closePage = 'indexes';
      } else {
        closePage = 'favorites';
      }

      // this.currentAPI.updateByFilter('input', newAuditory);

      this.checkButtons.forEach((el, index) => {
        if (el.name === closePage) {
          this.checkButtons[index].isChecked = false;
        }

        if (el.name === 'favorites') {
          favorites = el;
        }
        if (el.name === 'indexes') {
          indexes = el;
        }
      });

      this.setShowNumeration(indexes.isChecked);
      this.setShowFavorites(favorites.isChecked);
    },
    //
    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.callRequest(groupName, element.settingName.toLowerCase());
          return;
        }
        this[groupName][index].checked = false;
      });
    },

    callRequest(groupName, settingName) {
      switch (true) {
        case groupName === 'period':
          this.requestByDate(settingName);
          break;
        case groupName === 'type':
          this.requestByType(settingName);

          if (settingName === 'group') {
            this.showCatalogs = false;
          } else {
            this.showCatalogs = true;
          }
          break;
        case groupName === 'status':
          this.requestByStatus(settingName);

          break;
        default:
      }
    },

    checkLinkByRouteParams() {
      this.$nextTick(function () {
        if (this.$route.params.catalogsId) {
          for (let i = 0; i < this.getCategory.length; i += 1) {
            if (this.$refs[`folders${i}`] === undefined) {
              return;
            }

            if (this.getCategory[i].cat === this.$route.params.catalogsId) {
              this.openCatalogsByRef(this.$refs[`folders${i}`], 'cat');

              this.setClass(this.$refs[`folders${i}`].takeEvent('cat'), true);
              return;
            }

            const index = this.getCategory[i].sub_cat.indexOf(
              this.$route.params.catalogsId,
            );

            // console.log(this.getCategory[i].sub_cat, this.$route.params.catalogsId);
            if (index !== -1) {
              this.openCatalogsByRef(this.$refs[`folders${i}`], `subCat${index}`);
              // console.log('MOM CHECK', this.$refs[`folders${i}`].takeEvent(`subCat${index}`));

              this.setClass(this.$refs[`folders${i}`].takeEvent(`subCat${index}`), true);
            }
          }
        }
      });
    },

    openCatalogsByRef(ref, subcat) {
      const currnetRef = ref;

      currnetRef.listShow = true;

      this.$nextTick(() => {
        this.activeLink = ref.takeEvent(subcat);
        this.activeLink.classList.add('active');
        // this.isParamsNeed = true;
        // this.cancleRequest('tokenCommunities');
        this.requestByCategory(this.$route.params.catalogsId);
      });
    },

    // set and reset active classes
    setClass(event, checkByeRef = false) {
      switch (true) {
        case checkByeRef:
          return;
        case this.activeLink === null:
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          this.isParamsNeed = true;
          break;
        case this.activeLink !== event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          this.isParamsNeed = true;
          break;
        case this.activeLink === event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = null;
          this.isParamsNeed = false;
          break;
        default:
      }

      const resultStr = this.activeLink === null ? '' : this.activeLink.innerText;
      this.requestByCategory(resultStr);

      this.setRouting();
    },

    reset() {
      this.getCategory.forEach((element, index) => {
        this.$refs[`folders${index}`].resetList();
      });
    },

    resetCatalog() {
      if (this.activeLink !== null) {
        this.activeLink.classList.remove('active');
        this.activeLink = null;

        this.requestByCategory('');
        this.isParamsNeed = false;

        this.setRouting();
      }
    },

    setRouting() {
      const catalogId = this?.activeLink?.innerText ? this.activeLink.innerText : '';
      const newPath = routing.getRouteByCatalog(catalogId);

      this.$router.push(newPath);
    },
  },
  mounted() {
    this.period = this.getPeriod;
    this.type = this.getType;
    this.status = this.getStatus;
    this.checkButtons = this.getCheckButtons;

    this.checkLinkByRouteParams();
  },
  unmounted() {
    this.setShowNumeration(true);
  },
};
</script>

<style lang="scss" scoped>
$wigth-cell: 30%;

.container {
  width: 100%;
  height: 100%;
}

.filters {
  width: 100%;

  // .filters__radio-buttons
  &__radio-buttons {
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    margin: 10px 0 0 0;
    width: 100%;
  }

  &__buttons {
  }

  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }

  // .filters__checkbox
  &__checkbox {
    margin: 10px 0 0 0;
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 20px;
    font-weight: 700;
    color: #5e82a8;
  }
  &__icon {
    width: 20px;
    height: 20px;
  }
}

.line {
  display: block;
  width: 100%;
  content: "";
  height: 0;
  display: block;
  border-top: 1px solid #d8dfea;
  margin: 10px 0 10px 0;
}
.categories {
  width: 100%;

  &__title {
    font-weight: 700;
    font-size: 18px;
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    height: 30px;
  }
}

.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;

  margin-top: 18px;
}
@media (max-width: 1600px) {
  .static-info {
    text-align: start;
  }
}
</style>
