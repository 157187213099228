<template>
  <div>
    <div class="auditory">
      <p class="auditory__title">Аудитория</p>

      <close-icon @click="resetAuditory"></close-icon>
    </div>

    <double-slider ref="slider"/>
  </div>
</template>

<script>
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';
import DoubleSlider from '@/components/common/slider/DoubleSlider.vue';

export default {
  components: {
    CloseIcon,
    DoubleSlider,
  },
  methods: {
    // reset auditory in slider component
    resetAuditory() {
      this.$refs.slider.resetAuditory();
    },
  },
};
</script>

<style lang="scss" scoped>
.auditory {
  display: flex;
  justify-content: space-between;

  margin-top: 23px;
  height: 30px;
  align-items: center;
  position: relative;

  // .auditory__title
  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }
}
</style>
